import { Injectable, inject, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, map, throwError } from 'rxjs';
import {
  IGuardian,
  IGuardianListItem,
  IGuardianPayload,
  IGuardianQueryParams,
  IPagination,
  IResponse,
  IStudent,
  IStudentQueryParams,
  Idropdown,
} from '@shared/interfaces';
import { environment } from 'src/environments/environment';
import { IPaginatedResponse } from '@shared/interfaces';
import { FeedbackService } from '@shared/services/feedback.service';
import { TranslocoService } from '@jsverse/transloco';
import { InfiniteScrollCustomEvent } from '@ionic/angular/standalone';
import { UserStatus } from '@shared/enums';

@Injectable({
  providedIn: 'root',
})
export class GuardianService {
  private feedbackService = inject(FeedbackService);
  private http = inject(HttpClient);
  private translocoService = inject(TranslocoService);

  BE_API_BASE_URL = environment.BE_API_BASE_URL;

  private studentsSignal = signal<Idropdown[]>([]);
  readonly studentsDropdown = this.studentsSignal.asReadonly();

  private _studentsPagination = signal<IPagination | undefined>(undefined);
  readonly studentsPagination = this._studentsPagination.asReadonly();

  private guardiansSignal = signal<IGuardianListItem[]>([]);
  readonly guardiansList = this.guardiansSignal.asReadonly();

  private guardiansPaginationSignal = signal<IPagination | null>(null);
  readonly guardiansPagination = this.guardiansPaginationSignal.asReadonly();

  private _studentsListSearchText = signal<string | undefined>(undefined);

  /**
   * Retrieves a guardian by their ID.
   * @param id - The ID of the guardian to retrieve.
   * @returns An Observable that emits the guardian data.
   */
  getGuardian(id: number | string) {
    return this.http
      .get<IResponse<IGuardian>>(`${this.BE_API_BASE_URL}/guardians/${id}`)
      .pipe(
        map((res) => {
          return res.data;
        }),
      );
  }
  populateStudents(v: Idropdown[]) {
    this.studentsSignal.set(v);
  }

  getStudentsList(
    params?: IStudentQueryParams,
    update = false,
    event?: InfiniteScrollCustomEvent,
  ) {
    return this.http
      .get<IPaginatedResponse<IStudent[]>>(`${this.BE_API_BASE_URL}/students`, {
        params: {
          ...params,
          ...(this._studentsListSearchText() && {
            searchText: this._studentsListSearchText(),
          }),
        },
      })
      .subscribe({
        next: (res) => {
          if (update) {
            const nextList: any = res.data.map((n) => ({
              value: n.id,
              displayedValue: n.fullName,
              ...n,
            }));
            this.studentsSignal.update((list) => {
              return list.concat(nextList);
            });
            event?.target.complete();
          } else {
            const list = res.data.map((n) => ({
              value: n.id,
              displayedValue: n.fullName,
            }));
            list.unshift({
              value: -1,
              displayedValue: this.translocoService.translate([
                'action.student.new.add',
              ]),
            });
            this.populateStudents(list);
          }
          this._studentsPagination.set(res.paginate);
        },
        error: (err) => {
          this.populateStudents([
            {
              value: -1,
              displayedValue: this.translocoService.translate([
                'action.guardian.new.add',
              ]),
            },
          ]);
          this._studentsPagination.set(err.error.paginate);
        },
      });
  }

  getActiveOrPausedStudents(
    additionalParams?: Partial<IStudentQueryParams>,
    update = false,
    event?: InfiniteScrollCustomEvent,
  ) {
    const defaultParams: Partial<IStudentQueryParams> = {
      userStatuses: [UserStatus.ACTIVE, UserStatus.PAUSED].join(','),
    };

    const params = {
      ...defaultParams,
      ...additionalParams,
    };

    return this.getStudentsList(params, update, event);
  }

  /**
   * Creates a new guardian.
   * @param guardian - The guardian payload.
   * @returns An observable that emits the response from the server.
   */
  createGuardian(guardian: IGuardianPayload): Observable<any> {
    return this.http.post(`${this.BE_API_BASE_URL}/guardians`, guardian);
  }

  /**
   * Updates a guardian with the specified ID.
   * @param guardianId - The ID of the guardian to update.
   * @param guardian - The partial payload containing the updated guardian data.
   * @returns An Observable that emits the response from the server.
   */
  updateGuardian(
    guardianId: string,
    guardian: Partial<IGuardianPayload>,
  ): Observable<any> {
    return this.http.put(
      `${this.BE_API_BASE_URL}/guardians/${guardianId}`,
      guardian,
    );
  }

  /**
   * Deactivates a guardian by updating their status to 'INACTIVE'.
   * @param guardianId - The ID of the guardian to deactivate.
   * @returns An Observable that emits the response from the server.
   */
  deactivateGuardian(guardianId: string): Observable<any> {
    return this.http.put(
      `${this.BE_API_BASE_URL}/guardians/${guardianId}/status`,
      {
        status: 'INACTIVE',
      },
    );
  }

  /**
   * Activates a guardian by updating their status to 'ACTIVE'.
   * @param guardianId - The ID of the guardian to activate.
   * @returns An Observable that emits the response from the server.
   */
  activateGuardian(guardianId: string): Observable<any> {
    return this.http.put(
      `${this.BE_API_BASE_URL}/guardians/${guardianId}/status`,
      {
        status: 'ACTIVE',
      },
    );
  }

  getGuardiansList(params?: IGuardianQueryParams) {
    return this.http
      .get<IPaginatedResponse<IGuardian[]>>(
        `${this.BE_API_BASE_URL}/guardians`,
        {
          params: {
            ...params,
          },
        },
      )
      .pipe(
        map((res) => {
          this.populateGuardians(
            this.mapGuardiansToGuardianListItems(res.data),
          );
          this.guardiansPaginationSignal.set(res.paginate);
          return res;
        }),
        catchError((err) => {
          if (err.status === 404 && err.error.paginate.totalItems === 0) {
            this.populateGuardians([]);
          }
          this.guardiansPaginationSignal.set(err.error.paginate);
          return throwError(() => new Error(err));
        }),
      );
  }

  populateGuardians(v: IGuardianListItem[]) {
    this.guardiansSignal.set(v);
  }

  mapGuardiansToGuardianListItems(guardians: IGuardian[]): IGuardianListItem[] {
    return guardians.map((guardian) => {
      return {
        id: guardian.id,
        fullName: guardian.fullName,
        phoneNumber: guardian.countryCode + guardian.phoneNumber,
        gender: guardian.gender,
        nationalId: guardian.nationalId,
        student: guardian.students?.map((s) => s.fullName).join(', ') ?? '',
        actions: '',
        status: guardian.status,
        userId: guardian.userId,
        campusId: [...new Set(guardian.campuses?.map((s) => s.name))].join(
          ', ',
        ),
        schoolId: [...new Set(guardian.schools?.map((s) => s?.name))].join(
          ', ',
        ),
        companyId: [...new Set(guardian.companies?.map((s) => s?.name))].join(
          ', ',
        ),
        academicYearId: guardian?.academicYears
          ?.map((item) => item.name)
          ?.join(', '),
        lastActive: guardian.userEvent
          ? guardian.userEvent[0]?.createdAt
          : null,
      };
    });
  }

  /* shared methods between listing and profile */
  onDeactivateGuardian = async (onConfirm: () => void) => {
    await this.feedbackService.openFeedbackModal(
      {
        type: 'warning',
        modalTitle: this.translocoService.translate(
          'global.deactivate_account.title',
        ),
        modalMessage: this.translocoService.translate(
          'user_management.deactivate_account_alert.txt',
        ),
        primaryBtnStr: this.translocoService.translate(
          'global.yes_deactivate.btn',
        ),
        secondaryBtnStr: this.translocoService.translate(
          'global.no_cancel.btn',
        ),
      },
      () => onConfirm(),
    );
  };

  async onActivateGuardian(onConfirm: () => void) {
    await this.feedbackService.openFeedbackModal(
      {
        type: 'warning',
        modalTitle: this.translocoService.translate(
          'global.activate_account.title',
        ),
        modalMessage: this.translocoService.translate(
          'user_management.activate_account_alert.txt',
        ),
        primaryBtnStr: this.translocoService.translate(
          'global.yes_activate.btn',
        ),
        secondaryBtnStr: this.translocoService.translate(
          'global.no_cancel.btn',
        ),
      },
      () => onConfirm(),
    );
  }

  updateStudentsListSearchText(v: string | undefined) {
    this._studentsListSearchText.set(v);
  }
}
